import { css } from 'styled-components';
import theme from './theme';

const heightMargin = (width: number | undefined): number => {
    let height;
    if (width) {
        if (width >= parseInt(theme.mediaQueries.xl)) {
            height = 80;
        } else if (width >= parseInt(theme.mediaQueries.large)) {
            height = 120;
        } else if (width >= parseInt(theme.mediaQueries.medium)) {
            height = 182;
        } else {
            height = 226;
        }
    } else {
        height = 0;
    }

    return height;
};

const marginTop = css`
    margin-top: ${() => heightMargin(parseInt(theme.mediaQueries.small))}px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        margin-top: ${() =>
            heightMargin(parseInt(theme.mediaQueries.medium))}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        margin-top: ${() => heightMargin(parseInt(theme.mediaQueries.large))}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        margin-top: ${() => heightMargin(parseInt(theme.mediaQueries.xl))}px;
    }
`;

const maxHeight = css`
    max-height: calc(
        100vh - ${() => heightMargin(parseInt(theme.mediaQueries.small))}px
    );

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        max-height: calc(
            100vh - ${() => heightMargin(parseInt(theme.mediaQueries.medium))}px
        );
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        max-height: calc(
            100vh - ${() => heightMargin(parseInt(theme.mediaQueries.large))}px
        );
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        max-height: calc(
            100vh - ${() => heightMargin(parseInt(theme.mediaQueries.xl))}px
        );
    }
`;

export { marginTop, maxHeight, heightMargin };
