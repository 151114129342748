import animejs from 'animejs';
import { heightMargin } from '../constants/ContentStyle';

interface AnimationObject {
    [key: string]: any;
}

const offset = (el: HTMLElement | null) => {
    if (el) {
        const rect = el.getBoundingClientRect();
        return { top: rect.top, left: rect.left };
    } else {
        return { top: 0, left: 0 };
    }
};

const getAnim = (
    item: HTMLElement,
    width: number | undefined,
    height: number | undefined,
    TRANSITION_LEN: number
): any => {
    const bodyEl = document.querySelector('body') as HTMLBodyElement;
    bodyEl.style.pointerEvents = 'none';
    const world = item.id;
    const itemTitle = item.querySelector(
        'div[class*="World__Title"]'
    ) as HTMLDivElement;
    const itemPulse = item.querySelector(
        'div[class*="World__Pulse"]'
    ) as HTMLDivElement;
    const itemHover = item.querySelector(
        'div[class*="World__Hover"]'
    ) as HTMLDivElement;

    const archiveWorld = document.getElementById('archive');
    const aboutMeWorld = document.getElementById('about-me');
    const upcomingWorld = document.getElementById('upcoming');

    const archiveAnimation: AnimationObject = {
        targets: [archiveWorld],
    };
    const aboutMeAnimation: AnimationObject = {
        targets: [aboutMeWorld],
    };
    const aboutMeText: AnimationObject = {
        targets: [
            document.querySelector(
                '#about-me div[class*="World__TitleContainer"]'
            ),
        ],
    };
    const upcomingAnimation: AnimationObject = {
        targets: [upcomingWorld],
    };
    const pulseAnimation = {
        targets: [itemPulse],
        background: ['#fff'],
    };
    let titleAnimation: AnimationObject = { targets: [itemTitle] };
    const centerHeight = height ? height / 2 : 0;

    if (itemHover) {
        itemHover.style.opacity = '0';
    }

    if (width && width > 991) {
        if (world === 'archive') {
            archiveAnimation.left = '50%';
            archiveAnimation.translateX = ['0%', '-50%'];
            archiveAnimation.translateY = ['50%', '50%'];
            aboutMeAnimation.translateX = ['-50%', '500%'];
            upcomingAnimation.translateX = ['0', '550%'];
            upcomingAnimation.translateY = ['50%', '50%'];
            titleAnimation = {
                ...titleAnimation,
                left: ['calc(50% + 10.5px)', '50%'],
                bottom: '50%',
                translateX: ['-50%', '-50%'],
                translateY: ['50%', '50%'],
            };
        } else if (world === 'about-me') {
            aboutMeAnimation.bottom = '50%';
            aboutMeAnimation.translateX = ['-50%', '-50%'];
            aboutMeAnimation.translateY = ['0', '50%'];
            aboutMeText.maxWidth = ['100px', '256px'];
            archiveAnimation.translateY = ['50%', '-450%'];
            upcomingAnimation.translateY = ['50%', '-450%'];
            titleAnimation = {
                ...titleAnimation,
                left: ['calc(50% + 10.5px)', '50%'],
                bottom: '50%',
                translateX: ['-50%', '-50%'],
                translateY: ['50%', '50%'],
                maxWidth: ['256px', '700px'],
            };
        } else if (world === 'upcoming') {
            upcomingAnimation.right = '50%';
            upcomingAnimation.translateX = ['0%', '50%'];
            upcomingAnimation.translateY = ['50%', '50%'];
            aboutMeAnimation.translateX = ['-50%', '-600%'];
            archiveAnimation.translateX = ['0', '-550%'];
            archiveAnimation.translateY = ['50%', '50%'];
            titleAnimation = {
                ...titleAnimation,
                right: ['calc(50% + 10.5px)', '50%'],
                bottom: '50%',
                translateX: ['50%', '50%'],
                translateY: ['50%', '50%'],
            };
        }
    } else {
        const archiveWorldOffset = offset(archiveWorld);
        const aboutMeWorldOffset = offset(aboutMeWorld);
        const upcomingWorldOffset = offset(upcomingWorld);

        const layoutContainer = document.querySelector(
            'div[class*="Layout__Container"]'
        ) as HTMLDivElement;
        layoutContainer.style.height =
            'calc(100vh - ' + heightMargin(width) + 'px)';

        archiveAnimation.marginTop = ['0px', '0px'];
        archiveAnimation.left = [
            `${archiveWorldOffset.left}px`,
            `${archiveWorldOffset.left}px`,
        ];

        aboutMeAnimation.marginTop = ['0px', '0px'];
        aboutMeAnimation.left = [
            `${aboutMeWorldOffset.left}px`,
            `${aboutMeWorldOffset.left}px`,
        ];

        upcomingAnimation.marginTop = ['0px', '0px'];
        upcomingAnimation.left = [
            `${upcomingWorldOffset.left}px`,
            `${upcomingWorldOffset.left}px`,
        ];

        if (archiveWorld) {
            archiveWorld.style.position = 'fixed';
        }
        if (aboutMeWorld) {
            aboutMeWorld.style.position = 'fixed';
        }
        if (upcomingWorld) {
            upcomingWorld.style.position = 'fixed';
        }
        if (world === 'archive') {
            archiveAnimation.translateY = ['0%', '-50%'];
            archiveAnimation.top = [
                `${archiveWorldOffset.top}px`,
                `${centerHeight + heightMargin(width) / 2}px`,
            ];

            upcomingAnimation.top = [`${upcomingWorldOffset.top}px`, '-500px'];
            aboutMeAnimation.top = [`${aboutMeWorldOffset.top}px`, '1500px'];

            itemTitle.style.left = '50%';
            itemTitle.style.transform = 'translate(-50%,50%)';
        } else if (world === 'about-me') {
            aboutMeAnimation.translateY = ['0%', '-50%'];
            aboutMeAnimation.top = [
                `${aboutMeWorldOffset.top}px`,
                `${centerHeight + heightMargin(width) / 2}px`,
            ];

            upcomingAnimation.top = [`${upcomingWorldOffset.top}px`, '-1500px'];
            archiveAnimation.top = [`${archiveWorldOffset.top}px`, '-1500px'];

            itemTitle.style.bottom = '50%';
            itemTitle.style.transform = 'translate(-50%,50%)';
            itemTitle.style.maxWidth = 'none';
            itemTitle.style.letterSpacing = '0';
        } else if (world === 'upcoming') {
            upcomingAnimation.translateY = ['0%', '-50%'];
            upcomingAnimation.top = [
                `${upcomingWorldOffset.top}px`,
                `${centerHeight + heightMargin(width) / 2}px`,
            ];

            archiveAnimation.top = [`${archiveWorldOffset.top}px`, '1500px'];

            aboutMeAnimation.top = [`${aboutMeWorldOffset.top}px`, '1500px'];

            itemTitle.style.right = '50%';
            itemTitle.style.transform = 'translate(50%,50%)';
            itemTitle.style.letterSpacing = '0';
        }
    }

    const animation = animejs
        .timeline({
            duration: TRANSITION_LEN * 1000 - 50,
            easing: 'easeInOutQuad',
        })
        .add(titleAnimation, 0)
        .add(archiveAnimation, 0)
        .add(pulseAnimation, 0)
        .add(aboutMeAnimation, 0)
        .add(aboutMeText, 0)
        .add(upcomingAnimation, 0);
    animation.finished.then(() => (bodyEl.style.pointerEvents = 'auto'));

    return;
};

export default getAnim;
