import useWindowSize from '../hooks/useWindowSize';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
import Layout from '../components/Layout';
import World from '../components/World';
import { marginTop, heightMargin } from '../constants/ContentStyle';
import getAnim from '../animations/homepage';
import { graphql } from 'gatsby';

const TRANSITION_DELAY = 1.2;
const TRANSITION_LEN = 1.2;

interface Props {
    data: {
        allFile: {
            edges: [
                {
                    node: {
                        childMarkdownRemark: {
                            frontmatter: {
                                [key: string]: {
                                    homepage: {
                                        description?: string;
                                        title?: string;
                                        keywords: [
                                            {
                                                keyword: string;
                                            }
                                        ];
                                        photo_url?: string;
                                    };
                                };
                            };
                        };
                    };
                }
            ];
        };
    };
}

const Home: FC<Props> = ({ data }) => {
    const { locale } = useIntl();
    const { width, height } = useWindowSize();

    const metaData =
        data.allFile.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Meta`
        ]?.homepage &&
        data.allFile.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Meta`
        ].homepage;
    const listOfKeywords =
        metaData?.keywords && metaData.keywords.length
            ? metaData.keywords.map((el) => el.keyword)
            : undefined;

    return (
        <Layout
            title={
                metaData?.title && metaData.title !== ''
                    ? metaData.title
                    : undefined
            }
            description={
                metaData?.description && metaData.description !== ''
                    ? metaData.description
                    : undefined
            }
            keywords={listOfKeywords}
            metaImage={metaData?.photo_url}
        >
            <Container>
                <TransitionLink
                    to={`/${locale}/upcoming-world`}
                    exit={{
                        length: TRANSITION_LEN,
                        trigger: ({ node }: any) => {
                            const item = node.querySelector('#upcoming');
                            return getAnim(item, width, height, TRANSITION_LEN);
                        },
                    }}
                    entry={{ delay: TRANSITION_DELAY }}
                >
                    <World
                        position="right"
                        title="upcoming"
                        id="upcoming"
                        hasMarginMobile
                    />
                </TransitionLink>
                <TransitionLink
                    to={`/${locale}/archive-world`}
                    exit={{
                        length: TRANSITION_LEN,
                        trigger: ({ node }: any) => {
                            const item = node.querySelector('#archive');
                            return getAnim(item, width, height, TRANSITION_LEN);
                        },
                    }}
                    entry={{ delay: TRANSITION_DELAY }}
                >
                    <World
                        position="left"
                        title="archive"
                        id="archive"
                        hasMarginMobile
                    />
                </TransitionLink>
                <TransitionLink
                    to={`/${locale}/about-me-world`}
                    exit={{
                        length: TRANSITION_LEN,
                        trigger: ({ node }: any) => {
                            const item = node.querySelector('#about-me');
                            return getAnim(item, width, height, TRANSITION_LEN);
                        },
                    }}
                    entry={{ delay: TRANSITION_DELAY }}
                >
                    <World
                        position="bottom"
                        title="about_me"
                        id="about-me"
                        hasMarginMobile
                    />
                </TransitionLink>
            </Container>
        </Layout>
    );
};

const Container = styled.div`
    ${marginTop};
    position: relative;
    width: 100vw;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.medium))}px
        );
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.large))}px
        );
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        height: calc(
            100vh -
                ${({ theme }) =>
                    heightMargin(parseInt(theme.mediaQueries.xl))}px
        );
    }
`;

export const query = graphql`
    query MetaHome {
        allFile(filter: { absolutePath: { regex: "/meta_data/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlMeta {
                                homepage {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                            enMeta {
                                homepage {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Home;
